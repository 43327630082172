import * as React from 'react';
import DefaultAppView from '../app-view/default-app-view';
import { getUserPreferredLocale } from '../functions/utils';
import { useSitePrivacyPolicyI18N_IT } from '../hooks/site-strapi-i18n-IT/site-privacypolicy-i18n-IT';
import { useSitePrivacyPolicyI18N_EN } from '../hooks/site-strapi-i18n-EN/site-privacypolicy-i18n-EN';
import { getTheme } from '../functions/theme';
import { SEOData } from '../constants/types';
import { StaticQueryDocument, graphql, useStaticQuery } from 'gatsby';
import { SEO } from '../components/seo';

const PrivacyPolicy = () => {

  const _userLocale: string = getUserPreferredLocale();
  const _currentTheme: 'light' | 'dark' = getTheme();

  let strapiI18NContent: any | undefined;
  if (_userLocale.includes('it') || _userLocale.includes('IT')) {
    strapiI18NContent = useSitePrivacyPolicyI18N_IT();
  } else {
    strapiI18NContent = useSitePrivacyPolicyI18N_EN();
    strapiI18NContent = {
      title: strapiI18NContent.title,
      privacyPolicy: strapiI18NContent.privacyPolicy.data.privacyPolicy
    }
  }

  return (
    <DefaultAppView currentUrl='/privacypolicy' userLocale={_userLocale}>
      <div className={`px-5 pt-20 pb-10 md:px-16 sm:px-10 lg:px-36 lg:pt-20 lg:pb-40  ${_currentTheme === 'light' ? 'text-black' : 'text-cardinal-white'}`}>
        <div>
          <p className="c18 c8 c52"><span className="c20 c46"></span></p>
        </div>
        <p className="c21 c18 c8"><span className="c24 c50"></span></p>
        <p className="c30 c18 c8"><span className="c24 c34"></span></p>
        <p className="c30 c18"><span className="flex text-xl font-bold pt-7 lg:text-7xl">Cookie Policy - cardinal.solar </span></p>
        <p className="py-2 text-xs lg:py-4 c18 c30 lg:text-xl">
          <span className="c41">Ultimo aggiornamento: [</span>
          <span className="c41 c46">03/07/2023</span>
          <span className="c38">]</span>
        </p>
        <hr/>
        <p className="c21 c18 c8"><span className="c38"></span></p>
        <p className="c21 c18 c8"><span className="c38"></span></p><a id="t.ee9b18a36cfc57e1fafa0e1c662d672f72dc5f85"></a><a
          id="t.0"></a>
        <table className="c29">
          <tr className="c19">
            <td className="c51" colSpan={1} rowSpan={1}>
              <p className="pt-4 c16 c18">
                <span className="text-xs uppercase c31 md:text-sm lg:text-lg lg:flex">Che cos&rsquo;&egrave; questo documento?</span>
                <span><br />La presente
                  Cookie Policy contiene informazioni sulle diverse tipologie di cookie che potrebbero essere
                  installati sui tuoi dispositivi quando visiti il sito
                </span>
                <span className="c15 c48">
                  <a className="c32 text-cardinal"
                    href="https://www.google.com/url?q=https://www.cardinal.solar/&amp;sa=D&amp;source=editors&amp;ust=1688374795820514&amp;usg=AOvVaw3MEMYxksdFmIiYZ89tTKZm"> https://www.cardinal.solar/</a>
                </span>
              </p>
            </td>
          </tr>
        </table>
        <h3 className="py-4 text-xs uppercase c23 c18 c31 md:text-sm lg:text-lg lg:flex" id="h.gjdgxs">
          <span className="c6">Titolare del Trattamento e dettagli contatto</span>
        </h3>
        <p className="c18 c39">
          <span className="c31 c43">Alfercom</span>
          <span className="c31 c43">&nbsp;S.r.l.</span>
          <span className="c15">&nbsp;con sede legale in in Via del Progresso 34/C, Padova (IT).</span>
        </p>
        <p className="c18 c42">
          <span className="c31">E-mail:</span>
          <span>&nbsp;</span>
          <span className="c48 c15 c49">
            <a className="c32 text-cardinal" href="mailto:info@alfercom.com">
              info@alfercom.com
            </a>
          </span>
          <span className="c15">.</span>
        </p>
        <h3 className="py-4 text-xs uppercase c18 c23 md:text-sm lg:text-lg lg:flex" id="h.30j0zll">
          <span className="c6">Che cosa sono i cookie?</span>
        </h3>
        <p className="c2 c18">
          <span className="c40">I cookie sono piccoli file di testo che i siti visitati dall&#39;utente inviano e
            registrano sul suo computer o dispositivo mobile, per essere poi ritrasmessi agli stessi siti alla successiva
            visita. Proprio grazie ai cookie un sito ricorda le azioni e preferenze dell&#39;utente (come, ad esempio, i
            dati di login, la lingua prescelta, le dimensioni dei caratteri, altre impostazioni di visualizzazione, ecc.) in
            modo che non debbano essere indicate nuovamente quando l&#39;utente torni a visitare detto sito o navighi da una
            pagina all&#39;altra di esso. I cookie, quindi, sono usati per eseguire autenticazioni informatiche,
            monitoraggio di sessioni e memorizzazione di informazioni riguardanti le attivit&agrave; degli utenti che
            accedono ad un sito e possono contenere anche un codice identificativo unico che consente di tenere traccia
            della navigazione dell&#39;utente all&#39;interno del sito stesso per finalit&agrave; statistiche o
            pubblicitarie. Nel corso della navigazione su un sito, l&#39;utente pu&ograve; ricevere sul suo computer anche
            cookie di siti o di web server diversi da quello che sta visitando (c.d. cookie di &quot;terze parti&quot;).
            Alcune operazioni non potrebbero essere compiute senza l&#39;uso dei cookie, che in certi casi sono quindi
            tecnicamente necessari per lo stesso funzionamento del sito.
          </span>
        </p>
        <h3 className="py-4 text-xs uppercase c23 c18 md:text-sm lg:text-lg lg:flex" id="h.1fob9te">
          <span className="c6">Tipologia di cookie:</span>
        </h3>
        <ul className="c37 lst-kix_n2yeq3j1yqrm-0 start">
          <li className="c5 li-bullet-0">
            <span className="c31 text-cardinal">- Cookie strettamente necessari o tecnici: </span>
            <span className="c3">sono i
              cookie per consentire la navigazione del sito internet. Vengono utilizzati, ad esempio, per autenticarsi,
              per usufruire dei contenuti multimediali oppure per garantire alcune misure di sicurezza del sito e
              monitorarne il corretto funzionamento. In generale non &egrave; necessario acquisire il consenso preventivo
              e informato dell&rsquo;utente;
            </span>
          </li>
          <li className="c2 c45 c18 li-bullet-0">
            <span className="c31 text-cardinal">- Cookie di preferenza: </span>
            <span>i cookie di
              preferenza consentono al sito web di memorizzare informazioni che ne influenzano il comportamento o
              l&#39;aspetto, quali la lingua preferita o la localit&agrave; nella quale ti trovi.
            </span>
          </li>
          <li className="c2 c18 c45 li-bullet-0">
            <span className="c31 text-cardinal">- Cookie analitici: </span>
            <span>utilizzati unicamente in forma
              aggregata e anonima per ottenere dati statistici in merito alle visite al nostro sito.
            </span>
          </li>
          <li className="c18 c36 li-bullet-0">
            <span className="c31 text-cardinal">Cookie di Social Network: </span>
            <span className="c3">consentono di
              condividere con altri utenti i contenuti del sito che si sta visitando. Essi consentono al Social Network di
              identificare i propri utenti e raccogliere informazioni mentre navigano su altri siti. Questi cookie sono
              gi&agrave; presenti sui dispositivi dell&rsquo;utilizzatore se precedentemente accettati sulle suddette
              piattaforme. La raccolta e l&rsquo;uso da parte di tali terzi &egrave; regolato dalle rispettive
              informazioni privacy alle quali si deve fare riferimento.
            </span>
          </li>
        </ul>
        <h3 className="c27 c18" id="h.3znysh7">
          <span className="py-4 text-xs uppercase c14 md:text-sm lg:text-lg lg:flex">Cookie sul nostro sito</span>
        </h3>
        <a id="t.60b6742ee5d20b75a638c7608d947eb081a5f78e"></a><a id="t.1"></a>
        <div className='my-2 overflow-x-auto'>
          <table className={`c29 w-full border-collapse ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`}>
            <thead className=''>
              <tr className="c4">
                <th className={`border-2 c10 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c35 c31">Nome</span></p>
                </th>
                <th className={`border-2 c10 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c35 c31">Finalit&agrave;</span></p>
                </th>
                <th className={`border-2 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c35 c31">Durata</span></p>
                </th>
                <th className={`border-2 c10 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c35 c31">Provider</span></p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="c33">
                <td className={`border-2 c28 c13 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={4} rowSpan={1}>
                  <p className="c2"><span className="flex justify-center uppercase text-cardinal c20">Cookie Analitici (statistiche)</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_ga</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Registra un ID univoco utilizzato per generare dati statistici su come il
                    visitatore utilizza il sito internet.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">2 anni </span></p>
                </td>
                <td className={`border-2 c7${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">cardinal.solar</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_ga_#</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Utilizzato da Google Analytics per raccogliere dati sul numero di volte che
                    un utente ha visitato il sito internet, oltre che i dati per la prima visita e la visita pi&ugrave;
                    recente.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">2 anni </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">cardinal.solar</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_hjAbsoluteSessionInProgress</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Questo cookie viene utilizzato per contare quante volte un sito &egrave;
                    stato visto da diversi visitatori; a tale fine viene assegnato al visitatore un numero
                    identificativo casuale, che consente al visitatore di non essere conteggiato due volte.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">1 giorno </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">cardinal.solar</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_hjFirstSeen</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Questo cookie viene e utilizzato per determinare se il visitatore ha
                    gi&agrave; visitato il sito o se &egrave; un nuovo</span></p>
                  <p className="c16"><span className="c3">visitatore.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">1 giorno </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">cardinal.solar</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_hjIncludedInSessionSample_#</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Raccoglie statistiche sugli accessi al sito internet, come numero di
                    accessi, tempo medio trascorso sul sito internet e quali pagine sono state lette.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">1 giorno </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">cardinal.solar</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_hjRecordingEnabled</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Raccoglie dati relativi alla navigazione e al comportamento dell&#39;utente
                    sul sito web - viene</span></p>
                  <p className="c16"><span className="c3">utilizzato al fine di stilare report statistici e mappe termiche per il
                    proprietario del sito.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Sessione </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">hotjar.com</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_hjRecordingLastActivity</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Imposta un ID univoco per la sessione. Ci&ograve; consente al sito web di
                    ottenere dati sul comportamento dei visitatori a fini statistici.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Sessione </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">hotjar.com</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_hjSession_#</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Raccoglie statistiche sugli accessi al sito internet, come numero di
                    accessi, tempo medio trascorso</span></p>
                  <p className="c16"><span className="c3">sul sito internet e quali pagine sono state lette.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">1 giorno </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">cardinal.solar</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_hjSessionUser_#</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Raccoglie statistiche sugli accessi al sito internet, come numero di
                    accessi, tempo medio trascorso s</span></p>
                  <p className="c16"><span className="c3">sul sito internet e quali pagine sono state lette.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">1 anno </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">cardinal.solar</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_hjTLDTest</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Registra dati statistici sul comportamento degli utenti sul sito web. Questi
                    vengono utilizzati per l&#39;analisi interna dall&#39;operatore del sito.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Sessione</span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">cardinal.solar</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">hjActiveViewportIds</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Questo cookie contiene una stringa identificativa della sessione corrente.
                    Contiene informazioni personali delle sottopagine visitate dal visitatore; le informazioni vengono
                    utilizzate per ottimizzare l&#39;esperienza del visitatore.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Persistente </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">hotjar.com</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">hjViewportId</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Salva la dimensione dello schermo dell&#39;utente per adattare la dimensione
                    delle immagini sul sito internet.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Sessione </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">hotjar.com</span></p>
                </td>
              </tr>
              <tr className="c33">
                <td className={`border-2 c28 c13 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={4} rowSpan={1}>
                  <p className="c2"><span className="flex justify-center uppercase c20 text-cardinal">Cookie di Marketing</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">_fbp</span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Utilizzato da Facebook per fornire una serie di prodotti pubblicitari come
                    offerte in tempo reale da inserzionisti terzi.</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">3 mesi </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">cardinal.solar</span></p>
                </td>
              </tr>
              <tr className="c33">
                <td className={`border-2 c13 c28 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={4} rowSpan={1}>
                  <p className="c2"><span className="flex justify-center uppercase c20 text-cardinal">Cookie non Classificati</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`border-2 c12 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">@@scroll# </span></p>
                </td>
                <td className={`border-2 c11 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c16"><span className="c3">Non classificato</span></p>
                </td>
                <td className={`border-2 c0 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Sessione </span></p>
                </td>
                <td className={`border-2 c7 ${_currentTheme === 'light' ? 'border-cardinal-black' : 'border-cardinal-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">cardinal.solar</span></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="c21 c18 c8">
          <span className="c3"></span>
        </p>
        <h3 className="c18 c27" id="h.2et92p0">
          <span className="py-4 text-xs uppercase c14 md:text-sm lg:text-lg lg:flex">Come posso disabilitarli?</span>
        </h3>
        <a id="t.c015115db759d6f8e712a0775d4b646d62febacc"></a><a id="t.2"></a>
        <div className='my-2 overflow-x-auto'>
          <table className={`c29 border-collapse ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`}>
            <thead>
              <tr className="c4">
                <td className={`c22 c26 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c31 c35">Nome</span></p>
                </td>
                <td className={`c9 c26 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c35 c31">Link</span></p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="c4">
                <td className={`c22 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Google Chrome</span></p>
                </td>
                <td className={`c9 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3 text-cardinal">https://support.google.com/chrome/answer/95647</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`c22 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Safari</span></p>
                </td>
                <td className={`c9 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3 text-cardinal">https://support.apple.com/it-ie/guide/safari/sfri11471/mac</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`c22 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Firefox</span></p>
                </td>
                <td className={`c9 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3 text-cardinal">https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`c22 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Internet Explorer</span></p>
                </td>
                <td className={`c9 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span
                    className="c3 text-cardinal">https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d</span>
                  </p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`c22 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Facebook</span></p>
                </td>
                <td className={`c9 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3 text-cardinal">https://www.facebook.com/policies/cookies</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`c22 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Twitter</span></p>
                </td>
                <td className={`c9 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3 text-cardinal">https://help.twitter.com/it/rules-and-policies/twitter-cookies</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className={`c22 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3">Linkedin</span></p>
                </td>
                <td className={`c9 border-2 ${_currentTheme === 'light' ? 'border-black' : 'border-white'}`} colSpan={1} rowSpan={1}>
                  <p className="c2"><span className="c3 text-cardinal">https://it.linkedin.com/legal/cookie-policy?</span></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="c18 c8 c21"><span className="c3"></span></p>
        <h3 className="c27 c18" id="h.tyjcwt">
          <span className="py-4 text-xs uppercase c14 md:text-sm lg:text-lg lg:flex">Modifiche alla cookie policy</span>
        </h3>
        <p className="c21 c18">
          <span className="c3">La presente cookie policy pu&ograve; subire modifiche nel tempo, anche connesse
            all&rsquo;eventuale entrata in vigore di nuove normative di settore, all&rsquo;aggiornamento o erogazione di
            nuovi servizi ovvero ad intervenute innovazioni tecnologiche, per cui ti invitiamo a consultare periodicamente
            questa pagina.
          </span>
        </p>
      </div>
    </DefaultAppView>
  )

}

export default PrivacyPolicy;

// ----------------------- SEO CREATION ---------------------- //

export const seoData: StaticQueryDocument | undefined = graphql`
  query {
    strapiPrivacyPolicy {
      SEO {
        metaTitle
        metaDescription
        keywords
        preventIndexing
        pathname
      }
    }
  }
`

export const Head = () => {
  const seo: SEOData = seoData && useStaticQuery(seoData).strapiPrivacyPolicy.SEO;

  return (
    <SEO
      title={seo.metaTitle}
      description={seo.metaDescription}
      keywords={seo.keywords}
      preventIndexing={seo.preventIndexing}
      pathname={seo.pathname} />
  )
}