import { graphql, useStaticQuery } from "gatsby"

export const useSitePrivacyPolicyI18N_IT = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiPrivacyPolicy {
        localizations {
          data {
            attributes {
              title
              privacyPolicy
            }
          }
        }
      }
    }
  `)

  return data.strapiPrivacyPolicy.localizations.data[0].attributes;
}