import { graphql, useStaticQuery } from "gatsby"

export const useSitePrivacyPolicyI18N_EN = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiPrivacyPolicy {
        title
        privacyPolicy {
          data {
            privacyPolicy
          }
        }
      }
    }
  `)

  return data.strapiPrivacyPolicy;
}